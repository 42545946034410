.profile-dashboard-sec1 {
	display: inline-block;
	width: 100%;
	padding-top: 30px;
	text-align: center;
}

.profile-dashboard-sec1 h2 span {
	display: table;
	line-height: 1;
	margin: 0 auto;
}

.profile-dashboard-sec1 h2 span img {
	display: block;
	width: 121px;
}

.profile-dashboard-sec1 h2 {
	font-size: 28px;
	font-weight: 500;
	letter-spacing: 0.15px;
}

.profile-dashboard-sec2 .accordion-item {
	border-radius: 0;
	border: 0;
	margin: 0;
}

.profile-dashboard-sec2 .accordion-item h3 a {
	position: relative;
	display: block;
	width: 100%;
	font-size: 14px;
	padding: 10px 0;
	border-bottom: 1px solid #3e4245;
	font-weight: 600;
	color: #AD3132;
	letter-spacing: 0.5px;
	font-family: Open Sans;
	padding-right: 30px;
}

.profile-dashboard-sec2 .accordion-item h3 a:before {
	content: "";
	position: absolute;
	background-image: url("../public/images/drop-down-arrow.inline.svg");
	width: 28px;
	height: 28px;
	right: 0;
	-webkit-transition: all 0.32s ease-out;
	-moz-transition: all 0.32s ease-out;
	-o-transition: all 0.32s ease-out;
	transition: all 0.32s ease-out;
	top: 5px;
	transform: rotate(90deg);
}

.profile-dashboard-sec2 .accordion-item h3 a.collapsed:before {
	content: "";
	position: absolute;
	background-image: url("../public/images/drop-down-arrow.inline.svg");
	width: 28px;
	height: 28px;
	right: 0;
	transform: rotate(90deg);
}

.profile-dashboard-sec2 .accordion-body {
	padding: 20px 0;
}

.profile-dashboard-sec2 .accordion-body ul li {
	font-size: 14px;
}

.profile-dashboard-sec2 .accordion-item h3 a img {
	vertical-align: initial;
	margin-right: 5px;
}

.profile-dashboard-sec2 {
	display: inline-block;
	width: 100%;
	padding-top: 20px;
}

.profile-dashboard-colm {
	display: inline-block;
	width: 100%;
	margin-bottom: 20px;
	margin-top: 20px;
}

.dashboard-colm1 {
	border-bottom: 1px solid #f0f0f0;
	padding-bottom: 10px;
	margin-bottom: 10px;
	width: 100%;
	display: inline-block;
}

.dashboard-colm1 h3 {
	font-size: 22px;
	float: left;
	font-weight: 600;
	position: relative;
	top: 2px;
	text-transform: capitalize;
}

.dashboard-colm1 a {  float: right; font-size: 14px;color: rgb(49 49 49 / 50%); position: relative; top: 5px; text-decoration: underline !important; text-decoration-color: #3E4245;}

.profile-dashboard-colm abbr {
	font-size: 14px;
	display: block;
	color: rgb(49 49 49 / 80%);
}

.profile-dashboard-colm abbr img {
	margin-right: 2px;
	width: 22px;
	vertical-align: top;
}

.dashboard-colm2 p {
	font-size: 14px;
	margin: 0;
	margin-bottom: 2px;
}

.dashboard-colm2 {
	width: 100%;
	display: inline-block;
	position: relative;
	margin-top: 10px;
}

.dashboard-colm2 a.password-btn {
	position: absolute;
	font-size: 12px;
	right: 0;
	bottom: 0;
	font-style: italic;
	color: rgb(49 49 49 / 50%);
}

.dashboard-colm1 .switch {
	position: relative;
	display: inline-block;
	width: 26px;
	height: 16px;
	top: 3px;
	margin-right: 2px;
}

.newsletters-colm {
	float: right;
	margin-top: 4px;
	color: rgb(49 49 49 / 50%);
	letter-spacing: 0.5px;
}

.dashboard-colm1 .switch input {
	opacity: 0;
	width: 0;
	height: 0;
}

.dashboard-colm1 .slider {
	position: absolute;
	cursor: pointer;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: #C4601D;
	-webkit-transition: .4s;
	transition: .4s;
}

.dashboard-colm1 .slider:before {
	position: absolute;
	content: "";
	height: 12px;
	width: 12px;
	left: 2px;
	bottom: 2px;
	background-color: white;
	-webkit-transition: .4s;
	transition: .4s;
}

.dashboard-colm1 .switch input:checked+.slider {
	background-color: #AD3132;
}

.dashboard-colm1 .switch input:focus+.slider {
	box-shadow: 0 0 1px #2196F3;
}

.dashboard-colm1 .switch input:checked+.slider:before {
	-webkit-transform: translateX(10px);
	-ms-transform: translateX(10px);
	transform: translateX(10px);
}

/* Rounded sliders */
.dashboard-colm1 .slider.round {
	border-radius: 34px;
}

.dashboard-colm1 .slider.round:before {
	border-radius: 50%;
}

.profile-dashboard-colm p {
	font-size: 14px;
	margin: 0;
}

.dashboard-colm3 {display: inline-block;width: 100%;border: 1px solid #CCCCCC;      padding: 10px 15px 15px;
	margin-bottom: 15px; position: relative;    word-break: break-word; min-height: 60px; background-color: #fff; }		
.dashboard-colm3-sec{min-height: 200px;}

.dashboard-colm3 a {
	font-size: 14px;
	color: #946134;
	font-weight: 600;
}

.dashboard-colm3 p {
	font-size: 14px;
	margin: 0;
	letter-spacing: 0.25px;
	font-family: 'Barlow';
	font-weight: 40;
}

.dashboard-colm3 p b {
	color: #E7E7E7;
}

.product-item {
	display: inline-block;
	position: relative;
}

.product-item span {
	display: block;
	margin: 0 auto;
	background: #eeeeee;
}

.product-item h3 {
	font-size: 20px;
	font-weight: 600;
	line-height: 1.3;
	color: #000;
	padding-top: 12px;
}

.product-item p {
	font-size: 14px;
	padding-top: 10px;
	margin-bottom: 12px;
}

.product-item h4 {
	font-size: 20px;
	font-weight: 600;
	font-family: Open Sans;
	padding-bottom: 15px;
}

.add-btn-bag {
	position: relative;
	width: 100%;
	display: inline-block;
}

.add-btn-bag a {
	background-color: #C4601D;
	width: 100%;
	display: block;
	padding: 13px 0;
	border-radius: 5px;
	color: #fff;
	font-weight: 600;
	text-align: center;
	font-size: 12px;
	text-transform: uppercase;
	letter-spacing: 1.25px;
}

.delete-btn {
	position: absolute;
	right: 10px;
	top: 10px;
	z-index: 1;
}

.delete-btn a {
	display: block;
}

.delete-btn button {
	border: 0;
	background: none;
}

.profile-dashboard-sec2 .accordion-body .row .col-md-6 {
	padding: 0 5px;
	padding-bottom: 15px;
}

.profile-dashboard-sec2 .accordion-body .row {
	margin: 0 -5px;
}

.amaaya-acc-sec span {
	position: absolute;
	left: 20px;
	top: 12px;
}

.amaaya-acc-sec {
	margin-top: 20px;
	display: inline-block;
	width: 100%;
	border: 1px solid #3E4245;
	padding: 15px 15px 13px;
	background: #fff;
	position: relative;
	padding-left: 60px;
}

.amaaya-acc-sec p {
	margin: 0;
	padding-bottom: 2px;
	font-size: 14px
}

.amaaya-acc-sec p strong {
	color: #AD3132;
	font-weight: 600;
}

.amaaya-acc-sec2 {
	display: inline-block;
	width: 100%;
	box-shadow: 0px 0px 6px rgba(49, 49, 49, 0.2);
	border-radius: 5px;
	margin-bottom: 15px;
}

.amaaya-acc-colm {
	padding: 10px;
	display: table;
	width: 100%;
}

.amaaya-acc-colm ul {
	margin: 0;
}

.amaaya-acc-colm ul li {
	padding: 0 14px;
	position: relative;
}

.profile-dashboard-sec2 .accordion-body .amaaya-acc-colm ul.row {
	margin: 0 -14px;
	padding-bottom: 15px;
}

.amaaya-acc-colm ul li a {
	font-size: 12px;
	padding-top: 35%;
	display: block;
}

.amaaya-acc-colm h4 {
	font-family: Open Sans;
	font-size: 14px;
	padding-bottom: 6px;
}

.amaaya-acc-colm h5 {
	font-family: Open Sans;
	font-size: 12px;
	color: #C4601D;
	font-weight: 600;
}

.profile-dashboard-sec2 .accordion-body .row.date-point {
	margin: 0;
	border-top: 1px dotted #f0ddd1;
	padding: 5px 0 6px;
}

.row.date-point p {
	margin: 0;
	font-size: 14px;
}

.row.date-point p strong {
	font-weight: 600;
	padding-left: 2px;
}

.amaaya-acc-main {
	padding-top: 15px;
	width: 100%;
	display: table;
}

.amaaya-acc-main h3 {
	color: #000;
	font-size: 20px;
	font-weight: 600;
	padding-bottom: 10px;
}

.see-more-btn a {
	display: block;
	font-family: Open Sans;
	font-size: 14px;
	color: #C4601D;
	font-weight: 700;
	padding-top: 10px;
}

.amaaya-acc-colm ul li:before {
	content: "";
	background-image: url(../public/images/motif2.inline.svg);
	height: 16px;
	width: 16px;
	position: absolute;
	background-size: cover;
	top: 40%;
	left: -8px;
}

.amaaya-acc-colm ul li:first-child:before,
.amaaya-acc-colm ul li:last-child:before {
	display: none;
}

.dashboard-orders-sec1 {
	display: table;
	width: 100%;
	padding-bottom: 20px;
}

.dashboard-orders-sec1 h3 {
	float: left;
	font-size: 22px;
	font-weight: 600;
	color: #3e4245;
}

.dashboard-orders-sec1 h3 span {
	color: #3e4245;
}

.dashboard-orders-sec1 ul {
	float: right;
}

.dashboard-orders-sec1 ul li {
	float: left;
	margin-left: 10px;
}

.dashboard-orders-main {
	background: #fff;
	/* box-shadow: 0px 0px 6px rgb(49 49 49 / 20%); */
	border-radius: 0px;
	width: 100%;
	display: table;
	margin-bottom: 20px;
}

.dashboard-orders-colm1 {
	display: table;
	width: 100%;
	padding: 10px;
}

.orders-colm-row1 {
	width: 50%;
	float: left;
	padding-right: 10px;
}

.orders-colm-row2 {
	width: 100%;
	float: left;
}

.orders-colm-row1 span {
	display: block;
	background: #eeeeee;
	border-radius: 5px;
	padding: 5px;
	width: 100%;
	text-align: center;
}

.orders-colm-row1 a.more-a {
	font-size: 12px;
	color: #828282;
	display: block;
	padding-top: 10px;
	text-align: center;
}

.orders-colm-row2 h5 {
	font-size: 12px;
	color: #828282;
	letter-spacing: 0.5px;
	display: block;
	padding-bottom: 20px;
}

.orders-colm-row2 h5 abbr {
	display: block;
	color: #313131;
	font-size: 12px;
	padding-top: 8px;
}

.orders-colm-row3 h5 {
	font-size: 12px;
	color: #828282;
	letter-spacing: 0.5px;
	display: block;
	padding-bottom: 20px;
}

.orders-colm-row3 h5 abbr {
	display: block;
	color: #313131;
	font-size: 12px;
	padding-top: 8px;
}

.orders-colm-row3 {
	float: right;
}

.accordion-body .dashboard-orders-colm1 .row {
	margin: 0;
}

.dashboard-orders-colm1 .col-7,
.dashboard-orders-colm1 .col-5 {
	padding: 0;
}

.dashboard-orders-colm2 {
	display: table;
	width: 100%;
	border-top: 1px solid #3e4245;
	padding: 10px;
}

.dashboard-orders-colm2 h4 {
	font-size: 16px;
	float: left;
	font-weight: 600;
	font-family: Open Sans;
}

.dashboard-orders-colm2 h4:hover, .dashboard-orders-colm2:hover  {
	color: #AD3132;
 }

.dashboard-orders-colm2 h4 span {
	font-size: 14px;
	font-weight: 400;
	margin-right: 5px;
}

.dashboard-orders-colm2 h6 {
	float: right;
	color: #a9671e;
	font-size: 12px;
	font-family: Open Sans;
	letter-spacing: 1.25px;
	text-transform: uppercase;
	font-weight: 600;
	padding-top: 5px;
}

.dashboard-orders-colm2 h6 a {
	color: #AD3132;
}

.dashboard-orders-popup h5.modal-title {
	font-size: 14px;
	line-height: 1.4;
}

.dashboard-orders-popup .modal-header button.btn-close {
	font-size: 14px;
}

.dashboard-orders-popup .modal-header {
	border-bottom: 1px dotted #946134;
}

.dashboard-orders-popup .modal-dialog {
	margin: 0;
}

.dashboard-orders-popup .modal-content {
	border: 0;
	border-radius: 0;
	border-top-left-radius: 10px;
	border-top-right-radius: 10px;
}

.modal.fade.dashboard-orders-popup .modal-dialog {
	transform: translate(0, 50px);
	position: absolute;
	bottom: 0;
}

.modal.fade.dashboard-orders-popup.show .modal-dialog {
	transform: none;
}

.dashboard-orders-popup .modal-header {
	border-bottom: 1px solid #f6e4db;
}

.dashboard-orders-popup .modal-dialog {
	width: 100%;
}

.dashboard-orders-popup .modal-body {
	padding: 25px 0;
}

.dashboard-orders-popup ul li {
	display: block;
	width: 100%;
}

.dashboard-orders-popup ul li a {
	display: block;
	padding: 10px 1rem;
}

.dashboard-orders-popup ul li a.active {
	background-color: rgba(240, 237, 237, 0.40);
	color: #a9671e;
}

.referrals-sec {
	display: table;
	width: 100%;
	padding-top: 30px;
	text-align: center;
}

.referrals-sec-content {
	border-radius: 5px;
	background: #fff;
	display: table;
	width: 100%;
	box-shadow: 0px 3px 6px rgb(49 49 49 / 16%);
	overflow: hidden;
}

.referrals-sec abbr {
	display: table;
	margin: 0 auto 12px;
}

.referrals-sec-content h3 {
	font-size: 26px;
	font-weight: 700;
	padding-bottom: 8px;
	line-height: 1.3;
}

.referrals-sec-content p {
	font-size: 14px;
}

.referrals-sec-content h4 {
	border-radius: 5px;
	width: 100%;
	display: block;
	background: #f9f4e8;
	font-family: Open Sans;
	font-size: 18px;
	color: #000000;
	padding: 6px 10px;
	font-weight: 700;
	margin-bottom: 20px;
}

.code-re-btn {
	display: table;
	width: 100%;
}

.code-re-btn a {
	background: #C4601D;
	display: block;
	color: #fff;
	padding: 14px;
	font-size: 14px;
	font-weight: 600;
}

.referrals-row {
	padding: 15px 15px 0;
}

.code-re-btn span img {
	vertical-align: text-top;
	margin-right: 6px;
}

.referrals-sec2 {
	display: table;
	width: 100%;
	padding-top: 40px;
}

.referrals-sec2 h2 {
	font-size: 20px;
	font-weight: 700;
	padding-bottom: 15px;
}

.referrals-sec2 ul li {
	display: block;
	width: 100%;
	padding-bottom: 20px;
}

.referrals-sec2 ul li span {
	width: 100%;
	display: inline-block;
	padding-bottom: 12px;
}

.referrals-sec2 ul li:nth-child(even) {
	text-align: right;
}

.referrals-sec2 ul li:nth-child(even) img {
	float: right;
}

.referrals-sec2 ul li p {
	max-width: 280px;
	width: 100%;
}

.referrals-sec2 ul li:nth-child(even) p {
	float: right;
}

.amaaya-acc-colm ul li span {
	background: #eeeeee;
	border-radius: 5px;
	display: block;
	padding: 5px;
}

.amaaya-acc-colm h4 strong {
	font-weight: 600;
}

.profile-dashboard-sec2 .accordion-body .row.date-point .col-6 {
	padding: 0 10px;
}

.amaaya-acc-colm ul li.col-3 {
	width: 27%;
}

.amaaya-acc-colm ul li.col-2 {
	padding-left: 0;
	text-align: center;
}

.dashboard-colm3 h3 {
	color: #575757;
	letter-spacing: 0.5px;
	font-size: 14px;
	font-family: 'Barlow';
	top: 6px;
}

.dashboard-colm3 a {
	color: #575757;
	font-size: 12px;
	font-family: 'Barlow';
	font-weight: 400;
}

.dashboard-colm3 .dashboard-colm1 a img {
	width: 18px;
}

.dashboard-colm1 a img {
	margin-right: 3px;
	vertical-align: top;
	width: 22px;
}

.dashboard-colm3 .dashboard-colm1 {
	border: 0;
	margin-bottom: 5px;
	padding: 0;
}

.dashboard-colm4 {
	width: 100%;
	display: table;
}

.dashboard-colm4 p {
	letter-spacing: 0.25px;
	margin-bottom: 5px;
}

.dashboard-colm4 p b {
	color: #C4601D;
}

.dashboard-colm4 a {
	font-weight: 600;
	color: #C4601D;
	letter-spacing: 1.25px;
	text-transform: uppercase;
	display: block;
	font-size: 13px;
}

.journal-colm {
	position: relative;
	display: table;
	width: 100%;
}

.amaaya-acc-main .journal-colm h3 {
	float: left;
}

.amaaya-acc-main .journal-colm abbr {
	float: right;
}

.amaaya-acc-main .journal-colm abbr img {
	width: 26px;
}

.add-address {
	float: right;
}

.add-address a {
	color: #3e4245;
	font-family: 'Barlow';
	font-weight: 500;
}

.dashboard-colm1 .add-address img {
	margin-right: 0;
}

.deleteaddress-btn button {
	border: 0;
	background: none;
	margin: 0;
	padding: 0;
	display: block;
}

.deleteaddress-btn button img {
	display: block;
}


.search_form {
	width: 100%;
	display: block;
	position: relative;
}

.search_form input {
	border: 0;
	border-bottom: 1px solid #ccc;
	width: 90%;
	display: block;
	font-size: 12px;
	font-style: italic;
	padding: 7px;
	padding-left: 30px;
	color: #3e4245;
	padding-right: 30px;
}

.search_form a {
	position: absolute;
	left: 0;
	bottom: 3px;
}

.active.dashboard-colm3 {
	background-color: #E7E7E7;
	pointer-events: none;
	border: 1px solid #3e4245;
	border-radius: none;
}

.active.dashboard-colm3 .dashboard-colm1 a {
	pointer-events: auto;
	font-family: 'Barlow';
	color: #575757;
	font-weight: 400;
}

.profile_dashboard_colm {
	display: inline-block;
	width: 100%;
	margin-bottom: 20px;
	padding-top: 20px;
}

.checkout_right {
	float: right;
	line-height: 1.3;
}

.checkout_right img {
	display: none;
	margin-left: 8px;
}

.active.dashboard-colm3 .checkout_right {
	display: flex;
}

.active.dashboard-colm3 .checkout_right img {
	display: inline-block;
}

.hrd_cart {
	background: url('/images/fullbg_Desktop.webp');
	padding: 20px 0;
	width: 100%;
	top: 0;
	position: fixed;
	box-shadow: 0px 1px 2px rgb(82 53 40 / 16%);
	z-index: 99;
}

.cart_logo a {
	line-height: 1;
	display: block;
	text-align: center;
}

.icon-back-cart a {
	padding-top: 12px;
	display: block;
	cursor: pointer;
}

.checkout-ftr {
	width: 100%;
	display: inline-block;
	padding: 20px 0;
	background: #f9f8f8;
}

.checkout-ftr p {
	margin: 0;
	text-align: right;
	padding-top: 5px;
	color: #3E4245;
}

.cart_logo img {
	display: block;
	margin: 0 auto;
}

.dashboard-colm3:hover {
	/* background-color: hsl(42deg 59% 94% / 50%); */
	background-color: #E7E7E7;
}

.checkout_right:before {
	content: "Select this address";
	float: left;
	font-size: 12px;
	font-weight: 500;
	border: 1px solid #3e4245;
	padding: 1px 12px;
	border-radius: 10px;
	cursor: pointer;
}

.active.dashboard-colm3 .checkout_right:before {
	content: "Selected Address";
	background-color: #3e4245;
	font-family: 'Barlow';
	color: #fff;
}

.wishlist_sec_mobile .btnwishlistonclick {
	display: none;
}

.wishlist_sec_mobile .btnlistwish {
	display: none;
}

@media (min-width: 992px) {
	.wishlist_sec_mobile div h3 {
		font-size: 19px;
		min-height: 80px;
	}

	.hrd_cart {
		z-index: inherit;
	}

	.sticky_checkout {
		z-index: 99;
	}

	.add-address {
		padding-top: 5px;
	}

	.add_address_row {
		margin-top: 0px;
	}

	.amaaya-acc-sec {
		margin-top: 0;
	}

	.dashboard-colm3 {
		margin-bottom: 0;
		height: 100%;
	}

	.checkout_bottom .col-md-6 {
		margin-bottom: 15px;
	}

	.add-address a,
	.dashboard-colm3 a {
		cursor: pointer;
	}

	.my_dashboard_ul li {
		width: 100%;
		display: inline-block;
	}

	.my_dashboard_ul li a {
		letter-spacing: 0.15px;
		font-size: 16px;
		font-weight: 400;
		display: block;
		padding: 10px 25px;
		color: #3e4245;
	}

	.my_dashboard_ul {
		background: rgb(240 237 237 / 40%);
		padding-top: 15px;
		padding-bottom: 15px;
	}

	.my_dashboard_ul li a:hover {
		background-color: #f0eded;
	}

	.profile_dashboard_colm {
		margin-bottom: 50px;
		padding-top: 0;
	}

	.dashboard-colm1 h3 {
		font-size: 28px;
		font-weight: 500;
	}

	.dashboard-colm3 h3 {
		font-size: 14px;
	}

	.profile-dashboard-sec2 .accordion-item .accordion-header {
		display: none;
	}

	.profile-dashboard-colm {
		margin-top: 0;
	}

	.profile-dashboard-colm .col-md-6 {
		padding-bottom: 15px;
	}

	.dashboard-colm1.pt-3 {
		padding-top: 35px !important;
	}

	.profile-dashboard-sec1 h2 {
		font-size: 40px;
		font-weight: 400;
	}

	.profile-dashboard-sec1 h2 span img {
		width: 180px;
	}

	.profile-dashboard-sec1 {
		width: auto;
	}

	.dashboard-orders-sec1 h3 {
		font-size: 28px;
		font-weight: 500;
		line-height: 1.1;
	}

	.dashboard-orders-sec1 {
		position: relative;
		border-bottom: 1px solid #f0ddd1;
		padding-bottom: 10px;
		margin-bottom: 20px;
	}

	.dashboard-orders-main {
		/* box-shadow: -1px 1px 3px rgb(82 53 40 / 16%); */
		margin-bottom: 15px;
	}

	.dashboard-orders-sec1 ul li a {
		cursor: pointer;
	}

	.dashboard-orders-sec1 .search_form {
		width: 220px;
		top: -5px;
		position: absolute;
		right: 73px;
	}

	.search_form input {
		width: 100%;
		padding-right: 0;
	}

	.search_form a {
		cursor: pointer;
	}

	.dashboard-orders-colm1 {
		padding: 20px 20px 0;
	}

	.orders-colm-row2 h5,
	.orders-colm-row2 h5 abbr,
	.orders-colm-row3 h5,
	.orders-colm-row3 h5 abbr {
		font-size: 14px;
	}

	.dashboard-orders-colm2 {
		padding: 12px 20px;
	}

	.dashboard-orders-colm2 h4 {
		font-size: 20px;
	}

	.dashboard-orders-colm2 h6 {
		font-size: 14px;
	}

	.dashboard-orders-colm2 h4 span {
		margin-right: 10px;
	}

	.newsletters-colm {
		margin-top: 10px;
	}

	.whatsapp-updates {
		margin-top: 30px;
	}

	.whatsapp-updates .whatsapp-app {
		margin: 0 3px;
		vertical-align: sub;
	}

	.checkout-ftr p.checkout-ftr-text {
		padding-top: 0;
	}
}

@media (max-width: 767px) {
	.profile-dashboard-colm.wishlist_sec_mobile {
		margin-bottom: 0;
	}

	.cart_logo img {
		width: 100px;
		height: 50px;
		margin-top: 10px;
		margin-bottom: 10px;
	}

	.hrd_cart {
		padding: 0;
	}

	.icon-back-cart a {
		padding-top: 10px;
	}

	.checkout-ftr p {
		text-align: center;
		padding-top: 5px;
		font-size: 13px;
	}

	.checkout-ftr {
		position: fixed;
		padding: 7px 0;
		bottom: 0;
	}

	.checkout-ftr img {
		height: 20px;
		margin: 0 auto;
	}

	.checkout-ftr img.checkout-ftr-india {
		height: auto;
	}

	.hrd_cart h6 {
		font-size: 21px;
		font-family: Cormorant Garamond;
		text-align: center;
		display: flex;
		font-weight: 500;
		flex-direction: column;
		justify-content: center;
		height: 100%;
		line-height: 1;
	}

	.wishlist_sec_mobile .row {
		scroll-behavior: smooth;
		white-space: nowrap;
		transform: translateZ(0);
		flex-wrap: nowrap;
		overflow-y: auto;
		margin: 0 -7px;
	}

	.wishlist_sec_mobile .row .col-md-4 {
		padding-left: 7px;
		padding-right: 7px;
	}

	.wishlist_sec_mobile .row .col-md-4 {
		padding-bottom: 35px;
	}
}